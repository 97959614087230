import React from "react";
import "../Common.css";

const Blog = () => {
  return (
    <section class="section__container_1 blog__container_1" id="blog">
      <p class="section__subheader_1">Blog Posts</p>
      <h2 class="section__header_1">I Love To Write Articles</h2>
      <div class="blog__grid_1">
        <div class="blog__card_1">
          <img
            src="https://media.discordapp.net/attachments/1187774461227835476/1192067637782532176/blog-1.jpg?ex=65a7ba80&is=65954580&hm=12e181ecc8c90cad2bb58bfd00593be44eef65c385d21c4f8baf681d5c88ca32&=&format=webp"
            alt="blog"
          />
          <p>Case Study</p>
          <h4>Turning Clicks into Conversions with PPC</h4>
          <a href="#">Read More</a>
        </div>
        <div class="blog__card_1">
          <img
            src="https://media.discordapp.net/attachments/1187774461227835476/1192067638097084476/blog-2.jpg?ex=65a7ba80&is=65954580&hm=5d45fcb6ce6034537de8b8a13826b657dcd020dc3a5bee8e6a173cf37d5f97cf&=&format=webp"
            alt="blog"
          />
          <p>Educational/How-To</p>
          <h4>The Ultimate Guide to Website Accessibility</h4>
          <a href="#">Read More</a>
        </div>
        <div class="blog__card_1">
          <img
            src="https://media.discordapp.net/attachments/1187774461227835476/1192067638390706186/blog-3.jpg?ex=65a7ba80&is=65954580&hm=592725e1568d2d22a1ac65b2261eb44cca119d6e7a033695203f80f4c9219b74&=&format=webp"
            alt="blog"
          />
          <p>Trend Analysis</p>
          <h4>2023 Web Design Trends: What's In and What's Out</h4>
          <a href="#">Read More</a>
        </div>
      </div>
    </section>
  );
};

export default Blog;
