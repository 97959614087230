import React from "react";
import loginwave from "./img/wave.png";
import bgwave from "./img/bg.svg";
import fx1 from "../Assets/favicon.png";
import "./Login.css";
import { Link } from "react-router-dom";

const Login = () => {
  return (
    <div className="loginPage">
      <img class="login-wave" src={loginwave} alt="wave" />
      <div class="login-container">
        <div class="login-img">
          <img src={bgwave}  alt="bgwave"/>
        </div>
        <div class="login-content">
          <form action="#">
            <div>
              <img src={fx1} alt="fox" style={{ width: "136px", height: "185px" }} />
            </div>
            <h2 class="login-title">Seek Login</h2>
            <div class="  login-input-div login-one">
              <div class="login-i">
                <i class="fas fa-user"></i>
              </div>
              <div class="login-div">
                <h5>Username</h5>
                <input type="text" class="login-input" />
              </div>
            </div>
            <div class="login-input-div login-pass">
              <div class="login-i">
                <i class="fas fa-lock"></i>
              </div>
              <div class="login-div">
                <h5>Password</h5>
                <input type="password" class="login-input" />
              </div>
            </div>

            <input type="submit" class="login-btn" value="Login" />
            <Link
              href="https://anshu78.netlify.app/assest/cv/"
              target="_blank"
              class="login-social-icon"
              title="Developer's Profile"
            >
              {/* <!-- <i class="fab fa-linkedin-in"></i> --> */}
              <i class="fas fa-theater-masks"></i>
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
