import React from "react";
import "../Common.css";

const Projects = () => {
  return (
    <section class="section__container_1 project__container_1" id="project">
      <div class="project__header_1">
        <h2 class="section__header_1">Our Projects</h2>
        <div class="project__nav_1">
          <button
            class="btn_1 project__btn_1 mixitup-control-active"
            data-filter="all"
          >
            All
          </button>
          <button class="btn_1 project__btn_1" data-filter=".web">
            Web Apps
          </button>
          <button class="btn_1 project__btn_1" data-filter=".game">
            Games
          </button>
          <button class="btn_1 project__btn_1" data-filter=".design">
            Designs
          </button>
        </div>
      </div>

      <div class="project__grid_1">
        <div class="project__card_1 mix web">
          <img
            src="https://media.discordapp.net/attachments/1187774461227835476/1192067718149586975/project-1.jpg?ex=65a7ba93&is=65954593&hm=c7d5e964c43708c90398f3207d8c743a75db3fc57f344780a60df1309c54a61f&=&format=webp"
            alt="project"
          />
        </div>
        <div class="project__card_1 mix game">
          <img
            src="https://media.discordapp.net/attachments/1187774461227835476/1192067718376075335/project-2.jpg?ex=65a7ba93&is=65954593&hm=72e64a134e08577de99d0cbc585cb4046a9a1a6e893b45cb65fc3594c7a1290e&=&format=webp"
            alt="project"
          />
        </div>
        <div class="project__card_1 mix design">
          <img
            src="https://media.discordapp.net/attachments/1187774461227835476/1192067718610944101/project-3.jpg?ex=65a7ba93&is=65954593&hm=9d00a92afd73f0845b66a1cc585ec4cf6fceaaebf0a70f2fad41b501a985b240&=&format=webp"
            alt="project"
          />
        </div>
        <div class="project__card_1 mix web">
          <img
            src="https://media.discordapp.net/attachments/1187774461227835476/1192067718841647134/project-4.jpg?ex=65a7ba93&is=65954593&hm=6829c13e971e61df57c0c8da0f2289f092e660e2ebf9dfc288780bd120ce24cd&=&format=webp"
            alt="project"
          />
        </div>
        <div class="project__card_1 mix game">
          <img
            src="https://media.discordapp.net/attachments/1187774461227835476/1192067719055552532/project-5.jpg?ex=65a7ba93&is=65954593&hm=c637e98686a0815c3bb4568ac3d213dabd8578dde9c1b2ed9ebcc693424ff51c&=&format=webp"
            alt="project"
          />
        </div>
        <div class="project__card_1 mix design">
          <img
            src="https://media.discordapp.net/attachments/1187774461227835476/1192067719638548531/project-6.jpg?ex=65a7ba93&is=65954593&hm=cdf0eed2d5027343ee69cf92b22048e6f1b47c3872502a420dda5c032f1b9e8c&=&format=webp"
            alt="project"
          />
        </div>
      </div>
    </section>
  );
};

export default Projects;
