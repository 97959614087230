import React from "react";
import "../Hero/Hero.css";
import { Link } from "react-router-dom";
// import "./Yt.js";
const footer = () => {
  return (
    <footer className="footer">
      <div className="section__container footer__container">
        <div className="footer__col">
          <h3>
            <span className="gradient__header">Seek Solution LLP</span>
          </h3>
          <p>
            "Innovative software solutions provider, enhancing businesses with
            cutting-edge technology, ensuring efficiency, security, and client
            success."
          </p>
          <div className="footer__socials">
            <Link
              to="https://www.linkedin.com/company/seeksolutionllp/"
              target="_blank"
            >
              <span>
                <i className="ri-linkedin-fill"></i>
              </span>
            </Link>

            <Link
              to={`https://wa.me/+917266800839?text=${encodeURI(
                "Hey Seek Solution LLP, I came from your website and i need your help..!"
              )}`}
              target="_blank"
            >
              <span>
                <i className="ri-whatsapp-fill"></i>
              </span>
            </Link>

            <Link to="https://www.instagram.com/seeksolution/" target="_blank">
              <span>
                <i className="ri-instagram-fill"></i>
              </span>
            </Link>

            <Link to="#" target="_blank">
              <span>
                <i className="ri-twitter-fill"></i>
              </span>
            </Link>

            <Link to="#" target="_blank">
              <span>
                <i className="ri-facebook-fill"></i>
              </span>
            </Link>
          </div>
        </div>
        <div className="footer__col">
          <h4>Useful Links</h4>
          <Link to="/about">About</Link>
          <Link to="/service">Service</Link>
          <Link to="/project">Projects</Link>

          <Link to="/feedback">Feedback</Link>
        </div>
        <div className="footer__col">
          <h4>Community</h4>
          <Link to="/team">Team</Link>
          <Link to="/partners">Partners</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/login">Login</Link>
        </div>
        <div className="footer__col">
          <h4>Join Our Community</h4>
          <p>
            Joining a community is a wonderful way to connect with like-minded
            individuals, share common interests.
          </p>
          <form action="#">
            <input type="text" placeholder="Enter Email" />
            <button className="btn submit__btn">
              <i className="ri-send-plane-fill"></i>
            </button>
          </form>
        </div>
      </div>
    </footer>
  );
};

export default footer;
