import React from 'react';
import { Button, Carousel, Col, Dropdown, Flex, Image, Input, Row, Space } from 'antd';
import { VideoCameraAddOutlined } from '@ant-design/icons';
const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};
const boxStyle = {
    width: '100%',
    height: '100%',

};
const LRScroll = () => (
    <>
        <Row justify="center" align="center">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Flex gap="middle" align="center" vertical style={{ justifyContent: 'space-around', alignItems: 'center', ...boxStyle }}>
                    <div className=''>
                        <Carousel autoplay dotPosition='left'>
                            <div>
                                <h4>ONE.<br />Now free for everyone.</h4>
                                <p>We re-engineered the service we built for secure business<br /> meetings, Google Meet, to make it free and available for all.</p>

                            </div>
                            <div>
                                <h4>TWO<br />Now free for everyone.</h4>
                                <p>We re-engineered the service we built for secure business<br /> meetings, Google Meet, to make it free and available for all.</p>

                            </div>
                            <div>
                                <h4>THREE.<br />Now free for everyone.</h4>
                                <p>We re-engineered the service we built for secure business<br /> meetings, Google Meet, to make it free and available for all.</p>

                            </div>
                            <div>
                                <h4>FOUR.<br />Now free for everyone.</h4>
                                <p>We re-engineered the service we built for secure business<br /> meetings, Google Meet, to make it free and available for all.</p>

                            </div>
                        </Carousel>
                    </div>



                </Flex>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Flex gap="middle" align="start" vertical style={{ justifyContent: 'space-around', alignItems: 'center', ...boxStyle }}>

                    <div>
                        <Carousel autoplay dotPosition='right'>
                            <div>
                                <h3 style={contentStyle}>1</h3>
                            </div>
                            <div>
                                <h3 style={contentStyle}>2</h3>
                            </div>
                            <div>
                                <h3 style={contentStyle}>3</h3>
                            </div>
                            <div>
                                <h3 style={contentStyle}>4</h3>
                            </div>
                        </Carousel>
                    </div>
                </Flex>
            </Col>
        </Row>



    </>
);
export default LRScroll;