import React from "react";
import Navbar from "../Components/Navbar/Navbar";

const Web = () => {
  return (
    <div>
      <Navbar />
      <div>Web</div>
    </div>
  );
};

export default Web;
