import React, { useState } from 'react';
import country from "../JSONData/counter.json";
import requirement from "./requirement.svg";
import CodingData from '../JSONData/CodingData';
import {
    AutoComplete,
    Button,
    Card,
    Cascader,
    Checkbox,
    Col,
    Flex,
    Form,
    Image,
    Input,
    InputNumber,
    Row,
    Select,
} from 'antd';
import ScrollEffect from '../ScrollEffect/ScrollEffect';
const { Option } = Select;

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};

const input = {
    backgroundColor: "transparent",
}
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

const cardStyle = {
    width: "100%",
};
const imgStyle = {
    display: 'block',
    width: 273,
};
const ProjectForm = () => {
    const [form] = Form.useForm();
    const onFinish = (values) => {
        console.log('Received values of form: ', values);
    };
    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 100,
                }}

            >
                {country.map((items) => {
                    return (
                        <>

                            <Option value={items.dial_code} defaultValue="+91">{items.dial_code}</Option>
                        </>
                    )
                })}
            </Select>
        </Form.Item>
    );

    const allKeys = Object.keys(CodingData).reduce(
        (keys, category) => [...keys, ...Object.keys(CodingData[category])],
        []
    );
    return (

        <Flex gap="middle" align="center" vertical xm={24} xs={24}>

            <div className="about__content" style={{ height: "25vh" }} xs={24}>
                <h1>
                    Ping <span>  us..</span>
                </h1>
            </div>

            <Flex gap="middle" align="center" vertical xm={24} xs={24} style={{ width: '50%' }}>
                <Card
                    hoverable
                    style={cardStyle}
                    bodyStyle={{
                        padding: 0,
                        overflow: 'hidden',
                    }}
                    xs={24}
                    xm={24}
                >
                    <Flex justify="space-between">
                        <img
                            alt="avatar"
                            src={requirement}
                            style={imgStyle}
                            xs={24}
                            xm={24}
                        />
                        <Flex
                            vertical
                            align="flex-end"
                            justify="space-between"
                            style={{
                                padding: 32,
                            }}
                            xs={24}
                            xm={24}
                        >
                            {/* Form  */}
                            <Form
                                {...formItemLayout}
                                form={form}
                                layout="vertical"
                                name="register"
                                onFinish={onFinish}
                                initialValues={{
                                    prefix: '+91',
                                }}
                                style={{
                                    maxWidth: "100%",
                                }}
                                scrollToFirstError
                            >


                                {/* Name  */}
                                <Form.Item
                                    name="your name"
                                    label="Name"
                                    tooltip="Use  your original name..?"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your name..!',
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                {/* Email  */}
                                <Form.Item
                                    name="email"
                                    label="E-mail"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail!',
                                        },
                                        {
                                            required: true,
                                            message: 'Please input your E-mail!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                {/* Contact */}
                                <Form.Item
                                    name="phone"
                                    label="Phone"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your phone number!',
                                        },
                                    ]}
                                >
                                    <Input
                                        addonBefore={prefixSelector}
                                        maxLength={10}

                                    />
                                </Form.Item>

                                {/* Platform  */}
                                <Form.Item
                                    name="platform"
                                    label="Platform"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please choose platform..!',
                                        },
                                    ]}
                                >
                                    <Select placeholder="select your platform"  >
                                        <Option value="NFT Based">NFT Based</Option>
                                        <Option value="Static Website">Static Website</Option>
                                        <Option value="Dynamic Website">Dynamic Website</Option>
                                        <Option value="Static App">Static App</Option>
                                        <Option value="Dynamic App">Dynamic App</Option>
                                        <Option value="Iot Based">Iot Based</Option>

                                    </Select>
                                </Form.Item>

                                {/* Select lang.  */}
                                <Form.Item
                                    name="select-multiple"
                                    label="Coding : "
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select your core technologies!',
                                            type: 'array',
                                        },
                                    ]}
                                >
                                    <Select mode="multiple" placeholder="Please select your coding languages">
                                        {allKeys.map((key) => (
                                            <option key={key} value={key}>
                                                {key}
                                            </option>
                                        ))}

                                    </Select>
                                </Form.Item>

                                {/* Describe */}
                                <Form.Item
                                    name="describe"
                                    label="Describe"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input Intro',
                                        },
                                    ]}
                                >
                                    <Input.TextArea showCount maxLength={100} />
                                </Form.Item>




                                {/* T & C  */}
                                {/* <Form.Item
                                    name="agreement"
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                                        },
                                    ]}
                                    {...tailFormItemLayout}
                                >
                                    <Checkbox>
                                        I have read the <a href="" style={{ color: "blueviolet" }}>agreement</a>
                                    </Checkbox>
                                </Form.Item> */}

                                {/* Button  */}
                                <Form.Item {...tailFormItemLayout}>
                                    <Button type="primary" htmlType='submit'>
                                        Submit
                                    </Button>
                                </Form.Item>

                            </Form>

                        </Flex>
                    </Flex>
                </Card>
            </Flex>
            <br></br>
        </Flex>
    );
}
export default ProjectForm;