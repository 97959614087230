import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import NftPage from "../Components/NftPage/NftPage";


const Nft = () => {
  return (
    <div>
      <Navbar />
      <NftPage />

    </div>
  );
};

export default Nft;
