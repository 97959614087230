const Seeklogo = "https://media.discordapp.net/attachments/1187774461227835476/1193819319050711110/favicon.png?ex=65ae19e1&is=659ba4e1&hm=43f2bbbdfa63bd159d829d70ed915b36850344ed89d021e6cb538b4823e489ac&=&format=webp&quality=lossless";

const cardData = [
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817154450096158/0a2ea609-a134-4468-9cc0-fc9a28532373_1.jpg?ex=65ae17dd&is=659ba2dd&hm=4b7338fcd6b21e888f955341458f84f50123cdfc7f40b59aa5a890c88c0f7b26&=&format=webp",
        alt: "Example",
        title: "Ant Web Title 1",
        avatar: Seeklogo,
        desc: "This is web message",
        type: "web",
    },
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817154450096158/0a2ea609-a134-4468-9cc0-fc9a28532373_1.jpg?ex=65ae17dd&is=659ba2dd&hm=4b7338fcd6b21e888f955341458f84f50123cdfc7f40b59aa5a890c88c0f7b26&=&format=webp",
        alt: "Example",
        title: "Ant Web Title 1",
        avatar: Seeklogo,
        desc: "This is web message",
        type: "web",
    },
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817154450096158/0a2ea609-a134-4468-9cc0-fc9a28532373_1.jpg?ex=65ae17dd&is=659ba2dd&hm=4b7338fcd6b21e888f955341458f84f50123cdfc7f40b59aa5a890c88c0f7b26&=&format=webp",
        alt: "Example",
        title: "Ant Web Title 1",
        avatar: Seeklogo,
        desc: "This is web message",
        type: "web",
    },
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817154450096158/0a2ea609-a134-4468-9cc0-fc9a28532373_1.jpg?ex=65ae17dd&is=659ba2dd&hm=4b7338fcd6b21e888f955341458f84f50123cdfc7f40b59aa5a890c88c0f7b26&=&format=webp",
        alt: "Example",
        title: "Ant Web Title 1",
        avatar: Seeklogo,
        desc: "This is web message",
        type: "web",
    },
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817154450096158/0a2ea609-a134-4468-9cc0-fc9a28532373_1.jpg?ex=65ae17dd&is=659ba2dd&hm=4b7338fcd6b21e888f955341458f84f50123cdfc7f40b59aa5a890c88c0f7b26&=&format=webp",
        alt: "Example",
        title: "Ant Web Title 1",
        avatar: Seeklogo,
        desc: "This is web message",
        type: "web",
    },
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817154450096158/0a2ea609-a134-4468-9cc0-fc9a28532373_1.jpg?ex=65ae17dd&is=659ba2dd&hm=4b7338fcd6b21e888f955341458f84f50123cdfc7f40b59aa5a890c88c0f7b26&=&format=webp",
        alt: "Example",
        title: "Ant Web Title 1",
        avatar: Seeklogo,
        desc: "This is web message",
        type: "web",
    },
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817154718543873/f5a81a5e-fc7c-4fd6-a472-9f0987d026cf_1.jpg?ex=65ae17dd&is=659ba2dd&hm=aff230d2111501c26e8b95b73bcbeb80170eccba49e22fa262f7e4264212eafd&=&format=webp",
        alt: "Example",
        title: "Ant App Title 2",
        avatar: Seeklogo,
        desc: "This is messgage",
        type: "app",
    },
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817154718543873/f5a81a5e-fc7c-4fd6-a472-9f0987d026cf_1.jpg?ex=65ae17dd&is=659ba2dd&hm=aff230d2111501c26e8b95b73bcbeb80170eccba49e22fa262f7e4264212eafd&=&format=webp",
        alt: "Example",
        title: "Ant App Title 2",
        avatar: Seeklogo,
        desc: "This is messgage",
        type: "app",
    },
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817154718543873/f5a81a5e-fc7c-4fd6-a472-9f0987d026cf_1.jpg?ex=65ae17dd&is=659ba2dd&hm=aff230d2111501c26e8b95b73bcbeb80170eccba49e22fa262f7e4264212eafd&=&format=webp",
        alt: "Example",
        title: "Ant App Title 2",
        avatar: Seeklogo,
        desc: "This is messgage",
        type: "app",
    },
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817154718543873/f5a81a5e-fc7c-4fd6-a472-9f0987d026cf_1.jpg?ex=65ae17dd&is=659ba2dd&hm=aff230d2111501c26e8b95b73bcbeb80170eccba49e22fa262f7e4264212eafd&=&format=webp",
        alt: "Example",
        title: "Ant App Title 2",
        avatar: Seeklogo,
        desc: "This is messgage",
        type: "app",
    },
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817154718543873/f5a81a5e-fc7c-4fd6-a472-9f0987d026cf_1.jpg?ex=65ae17dd&is=659ba2dd&hm=aff230d2111501c26e8b95b73bcbeb80170eccba49e22fa262f7e4264212eafd&=&format=webp",
        alt: "Example",
        title: "Ant App Title 2",
        avatar: Seeklogo,
        desc: "This is messgage",
        type: "app",
    },
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817154718543873/f5a81a5e-fc7c-4fd6-a472-9f0987d026cf_1.jpg?ex=65ae17dd&is=659ba2dd&hm=aff230d2111501c26e8b95b73bcbeb80170eccba49e22fa262f7e4264212eafd&=&format=webp",
        alt: "Example",
        title: "Ant App Title 2",
        avatar: Seeklogo,
        desc: "This is messgage",
        type: "app",
    },
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817155272179742/43cdb8e9-1e3b-4163-8c51-23baf0a5b263_1.jpg?ex=65ae17dd&is=659ba2dd&hm=a6c78aed681ef8953c330a868079af2d57394dbb137fd6354c26236c4a8cf311&=&format=webp",
        alt: "Example",
        title: "Ant UI Title 3",
        avatar: Seeklogo,
        desc: "This is messgage",
        type: "ui",
    },
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817155272179742/43cdb8e9-1e3b-4163-8c51-23baf0a5b263_1.jpg?ex=65ae17dd&is=659ba2dd&hm=a6c78aed681ef8953c330a868079af2d57394dbb137fd6354c26236c4a8cf311&=&format=webp",
        alt: "Example",
        title: "Ant UI Title 3",
        avatar: Seeklogo,
        desc: "This is messgage",
        type: "ui",
    },
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817155272179742/43cdb8e9-1e3b-4163-8c51-23baf0a5b263_1.jpg?ex=65ae17dd&is=659ba2dd&hm=a6c78aed681ef8953c330a868079af2d57394dbb137fd6354c26236c4a8cf311&=&format=webp",
        alt: "Example",
        title: "Ant UI Title 3",
        avatar: Seeklogo,
        desc: "This is messgage",
        type: "ui",
    },
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817155272179742/43cdb8e9-1e3b-4163-8c51-23baf0a5b263_1.jpg?ex=65ae17dd&is=659ba2dd&hm=a6c78aed681ef8953c330a868079af2d57394dbb137fd6354c26236c4a8cf311&=&format=webp",
        alt: "Example",
        title: "Ant UI Title 3",
        avatar: Seeklogo,
        desc: "This is messgage",
        type: "ui",
    },
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817155272179742/43cdb8e9-1e3b-4163-8c51-23baf0a5b263_1.jpg?ex=65ae17dd&is=659ba2dd&hm=a6c78aed681ef8953c330a868079af2d57394dbb137fd6354c26236c4a8cf311&=&format=webp",
        alt: "Example",
        title: "Ant UI Title 3",
        avatar: Seeklogo,
        desc: "This is messgage",
        type: "ui",
    },
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817155272179742/43cdb8e9-1e3b-4163-8c51-23baf0a5b263_1.jpg?ex=65ae17dd&is=659ba2dd&hm=a6c78aed681ef8953c330a868079af2d57394dbb137fd6354c26236c4a8cf311&=&format=webp",
        alt: "Example",
        title: "Ant UI Title 3",
        avatar: Seeklogo,
        desc: "This is messgage",
        type: "ui",
    },
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817155272179742/43cdb8e9-1e3b-4163-8c51-23baf0a5b263_1.jpg?ex=65ae17dd&is=659ba2dd&hm=a6c78aed681ef8953c330a868079af2d57394dbb137fd6354c26236c4a8cf311&=&format=webp",
        alt: "Example",
        title: "Ant UI Title 3",
        avatar: Seeklogo,
        desc: "This is messgage",
        type: "ui",
    },
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817155272179742/43cdb8e9-1e3b-4163-8c51-23baf0a5b263_1.jpg?ex=65ae17dd&is=659ba2dd&hm=a6c78aed681ef8953c330a868079af2d57394dbb137fd6354c26236c4a8cf311&=&format=webp",
        alt: "Example",
        title: "Ant UI Title 3",
        avatar: Seeklogo,
        desc: "This is messgage",
        type: "ui",
    },
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817155595153418/edc5edc9-5baf-44a5-9a95-0cc61bdf0204_1.jpg?ex=65ae17dd&is=659ba2dd&hm=c2d75bf959c2df88dea7e17a79905c3c5be9b8320173f82808b7ee4264fa852f&=&format=webp",
        alt: "Example",
        title: "Ant Design Title 4",
        avatar: Seeklogo,
        desc: "This is messgage",
        type: "Apps"
    },
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817155800670218/ce531bc4-b2a8-44e7-90c1-15618b4cafc6_1.jpg?ex=65ae17dd&is=659ba2dd&hm=bd535c18615249558978a16dfd62a21b0debf55bd8254645fe946daac066d12e&=&format=webp",
        alt: "Example",
        title: "Ant Design Title 5",
        avatar: Seeklogo,
        desc: "This is messgage",
        type: "",
    },
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817156379484170/611bcc23-f289-499d-baf8-5ef631388492_2.jpg?ex=65ae17de&is=659ba2de&hm=46f61c7b8a5832d078a5e1c346b8887bce97ad7b4fe55533d8d211ee75e5cbab&=&format=webp",
        alt: "Example",
        title: "Ant Design Title 6",
        avatar: Seeklogo,
        desc: "This is messgage",
        type: "",
    },
    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817154173284372/6278e95c-9ce8-440f-8f4c-292ddb2b22d0_1.jpg?ex=65ae17dd&is=659ba2dd&hm=476999063eb1f71d436ba59c63494e6374a353e070b3a890a2314437246e6060&=&format=webp",
        alt: "Example",
        title: "Ant Design Title 7",
        avatar: Seeklogo,
        desc: "This is messgage",
        type: "",
    },

    {
        cover: "https://media.discordapp.net/attachments/1187774461227835476/1193817215967965224/da98a059-65ae-4d5d-9ff2-d07fbb67f6fd.jpg?ex=65ae17ec&is=659ba2ec&hm=c7ca753164d4659728300d7bb512ae54472414be0bd6ee16ecacae483c2b3fe3&=&format=webp",
        alt: "Example",
        title: "Ant Design Title 9",
        avatar: Seeklogo,
        desc: "This is messgage",
        type: "",
    },



]

export default cardData

