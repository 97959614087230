import React from 'react';
import { Collapse } from 'antd';
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
const items = [
    {
        key: '1',
        label: <h3>This is panel header 1</h3>,
        children: <h4>{text}</h4>,
    },
    {
        key: '2',
        label: <h3>This is panel header 2</h3>,
        children: <h4>{text}</h4>,
    },
    {
        key: '3',
        label: <h3>This is panel header 3</h3>,
        children: <h4>{text}</h4>,
    },
    {
        key: '4',
        label: <h3>This is panel header 4</h3>,
        children: <h4>{text}</h4>,
    },
    {
        key: '5',
        label: <h3>This is panel header 5</h3>,
        children: <h4>{text}</h4>,
    },
];
const Faq = () => <Collapse accordion items={items} />;
export default Faq;

// Dummy 
{/* <section className="faq">
        <div className="section__container faq__container">
          <h2 className="section__header">Frequently Asked Question</h2>
          <p className="section__subheader">
            Question and Answers. are listed questions and answers, all supposed
            to be comonly asked in some context.
          </p>
          <div className="question__accordian">
            <div className="question__box">
              <div className="title">
                <p>
                  <span>
                    <i className="ri-question-mark"></i>
                  </span>
                  How do I obtain technical support for your software?
                </p>
                <span className="icon" onClick={active}>
                  <i className="ri-arrow-down-line"></i>
                </span>
              </div>
              <div className="content">
                Contact our dedicated support team via email at
                support@example.com or visit our online support portal.
              </div>
            </div>
            <div className="question__box">
              <div className="title">
                <p>
                  <span>
                    <i className="ri-question-mark"></i>
                  </span>
                  What security measures are in place to protect user data?
                </p>
                <span className="icon" onClick={active}>
                  <i className="ri-arrow-down-line"></i>
                </span>
              </div>
              <div className="content">
                We implement robust encryption protocols and conduct regular
                security audits to ensure the utmost protection of user
                information.
              </div>
            </div>
            <div className="question__box">
              <div className="title">
                <p>
                  <span>
                    <i className="ri-question-mark"></i>
                  </span>
                  How frequently are software updates released, and how can I
                  install them?
                </p>
                <span className="icon" onClick={active}>
                  <i className="ri-arrow-down-line"></i>
                </span>
              </div>
              <div className="content">
                We release updates quarterly, and users receive notifications
                with easy-to-follow instructions for seamless installations.
              </div>
            </div>
            <div className="question__box">
              <div className="title">
                <p>
                  <span>
                    <i className="ri-question-mark"></i>
                  </span>
                  Can I customize the software to meet my specific business
                  needs?
                </p>
                <span className="icon" onClick={active}>
                  <i className="ri-arrow-down-line"></i>
                </span>
              </div>
              <div className="content">
                Yes, our software is designed with flexibility in mind, allowing
                users to customize certain features to align with their unique
                requirements.
              </div>
            </div>
          </div>
        </div>
      </section> */}