import React from "react";
import Navbar from "../Components/Navbar/Navbar";

const UiUx = () => {
  return (
    <div>
      <Navbar />
      <div> UI/ UX</div>
    </div>
  );
};

export default UiUx;
