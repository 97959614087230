import React from "react";
import "./Hero.css";
import Navbar from "../Navbar/Navbar";
// import usd from "./assets/usd.png";
// import usd_circ from "./assets/usd-circle.png";
// import eth from "./assets/ethereum.png";
// import tok from "./assets/token.png";
import security from "./assets/security.png";
import blockchain from "./assets/blockchain.png";
import tme from "./assets/time.png";
import padlock from "./assets/padlock.png";
import setting from "./assets/settings.png";
import waterDrop from "./assets/water-drops.png";
import goal from "./assets/goal.png";
import Footer from "../Footer/footer";
import Faq from "../Faq";
import Pricing from "../Pricing/Pricing";

const Hero = () => {
  function active() {
    const questionBox = document.getElementsByClassName("question__box");

    for (let i = 0; i < questionBox.length; i++) {
      questionBox[i].addEventListener("click", function () {
        this.classList.toggle("active");
      });
    }
  }
  return (
    <div style={{ background: "#100E17" }} >
      <header className="header">
        <Navbar />
        <div className="section__container header__container">
          <h1>Innovate. Transform. Thrive. IT Excellence.</h1>
          <p>
            Drive progress through creative solutions, adapt to change, prosper,
            and achieve excellence in information technology for sustained
            success and growth.
          </p>
          <button className="btn">Contact Us</button>
          <div className="header__banner">
            <div className="header__banner__grid">
              <div className="col">
                <i class="bx bxl-codepen col-i"></i>
                <p>Web Crush</p>
                <h4>100+</h4>
              </div>
              <div className="col">
                <i class="bx bxl-redux col-i"></i>
                <p>Android Warz</p>
                <h4>25+</h4>
              </div>
              <div className="col">
                <i class="bx bxl-aws col-i"></i>
                <p>Data-base Confusion</p>
                <h4>~0%</h4>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="rewards">
        <div className="section__container rewards__container">
          <div className="rewards__content">
            <h2 className="section__header">
              100%<sup>record</sup>
              <br />
              Industry Leading Rewards
            </h2>
            <p className="section__subheader">
              Recognized for exceptional achievements, setting benchmarks in the
              competitive IT software sector, leading the industry with
              groundbreaking innovations and solutions.
            </p>
          </div>
          <div className="rewards__grid">
            <div className="rewards__card">
              {/* <img src={usd} alt="rewards" /> */}
              <i class="bx bxl-meta col-ii" style={{ color: "#03B8FF" }}></i>
              <div className="rewards__details">
                <p>Meta</p>
                <h4>100%</h4>
              </div>
            </div>
            <div className="rewards__card">
              {/* <img src={usd_circ} alt="rewards" /> */}
              <i
                class="bx bxl-facebook col-ii"
                style={{ color: "	#4267B2" }}
              ></i>
              <div className="rewards__details">
                <p>Facebook</p>
                <h4>90%</h4>
              </div>
            </div>
            <div className="rewards__card">
              {/* <img src={eth} alt="rewards" /> */}
              <i class="bx bxl-unity col-ii"></i>
              <div className="rewards__details">
                <p>Unity</p>
                <h4>87%</h4>
              </div>
            </div>
            <div className="rewards__card">
              {/* <img src={tok} alt="rewards" /> */}
              <i class="bx bxl-android col-ii" style={{ color: "#4DAE58" }}></i>
              <div className="rewards__details">
                <p>Android</p>
                <h4>95%</h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="holders">
        <div className="section__container holders__container">
          <h2 className="section__header">Trusted by 2,000+ consumers</h2>
          <p className="section__subheader">
            Empower your digital experience with user-friendly, efficient, and
            innovative software solutions designed for seamless interaction.
          </p>
          <div className="holders__grid">
            <div className="holders__card">
              <span>
                <img src={security} alt="holder" />
              </span>
              <h4>Better Security</h4>
            </div>
            <div className="holders__card">
              <span>
                <img src={blockchain} alt="holder" />
              </span>
              <h4>Joint Family</h4>
            </div>
            <div className="holders__card">
              <span>
                <img src={tme} alt="holder" />
              </span>
              <h4>Better Uptime</h4>
            </div>
          </div>
          <div className="holders__content">
            Joint family focuses on the bonding of the whole life cycle of smart
            contracts, specializing in rigorous testing of economic attacks.
          </div>
        </div>
      </section>

      <section className="work">
        <div className="section__container work__container">
          <h2 className="section__header">What we serve..</h2>
          <p className="work__subheader">Providers and Consumers</p>
          <div className="work__grid">
            <div className="work__card">
              <span>
                <img src={padlock} alt="work" />
              </span>
              <h4> SSL</h4>
              <p>
                Encrypts data, ensures secure communication, protects user
                information online.
              </p>
            </div>
            <div className="work__card">
              <span>
                <img src={setting} alt="work" />
              </span>
              <h4>Always Boosted Emissions</h4>
              <p>
                Code efficiency and performance optimization for excellence.
              </p>
            </div>
            <div className="work__card">
              <span>
                <img src={waterDrop} alt="work" />
              </span>
              <h4>Cash Flow Availability</h4>
              <p>
                Available funds for operations and financial liquidity in the
                software sector.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="mission">
        <div className="section__container mission__container">
          <h2 className="section__header">Our Mission</h2>
          <p className="section__subheader">
            "Pioneering software advancements, our mission is to deliver
            cutting-edge solutions, ensuring user satisfaction, efficiency, and
            technological excellence in every project."
          </p>
          <div className="mission__grid">
            <div className="mission__list">
              <h4>Unify</h4>
              <h4>Simplify</h4>
              <h4>Innovate</h4>
            </div>
            <div className="mission__content">
              <p>
                "Harmonize diverse elements, streamline complexities, and
                introduce creative advancements, cultivating a cohesive,
                user-friendly, and cutting-edge software environment for
                enhanced efficiency and innovation."
              </p>
              <img src={goal} alt="mission" />
            </div>
          </div>
        </div>
      </section>

      <section className="pricing____list">
        <Pricing />
      </section>

      <section className="work">
        <div className="section__container work__container">
          <h2 className="section__header">Frequently Ask Questions...</h2>
          <p className="work__subheader">Most common questions asked </p>

        </div>
        <div className="section__container faq__container " style={{ paddingTop: "1rem" }} >

          <Faq />
        </div>
      </section>

      <section >
      </section>


      <Footer />
    </div>
  );
};

export default Hero;
