import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import FilterProjectPage from "../Components/Projects/FilterProjectPage";
import ProjectForm from "../Components/Projects/ProjectForm";
// import ScrollEffect from "../Components/ScrollEffect/ScrollEffect";
import Pricing from "../Components/Pricing/Pricing";
import Footer from "../Components/Footer/footer.js"

const Project = () => {
  return (
    <div>
      <Navbar />
      <div className="about__content" style={{ height: "50vh" }}>
        <h1>
          Here is our
          <br />
          integrated{" "}
          <span>
            projects..

          </span>
        </h1>


      </div>
      <FilterProjectPage />
      <ProjectForm />
      {/* <ScrollEffect /> */}
      <Pricing />
      <Footer />
    </div>
  );
};

export default Project;
