import React from "react";
// import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/footer";
import Aboutpg from "../Components/About/AboutPage";
import Banner from "../Components/Banner/Banner";
import Projects from "../Components/Projects/Projects";
import Service from "../Components/Service/Service";
import Blog from "../Components/Blog/Blog";
import BarLine from "../Components/BarLine/BarLine";

const About = () => {
  return (
    <div>
      <Aboutpg />
      <Banner />
      <Projects />
      <Service />
      <BarLine />
      <Blog />
      <Footer />
    </div>
  );
};

export default About;
