import React from 'react'
import LRScroll from '../Service/SubService/LRScroll'


const NftPage = () => {
    return (
        <div>
            <div>NFT</div>
            <LRScroll />
            {/* <RLScroll /> */}

        </div>
    )
}

export default NftPage
