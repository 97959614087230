import React from "react";
import Navbar from "../Components/Navbar/Navbar";

const Service = () => {
  return (
    <div>
      <Navbar />
      <div>I am Service Page</div>
    </div>
  );
};

export default Service;
