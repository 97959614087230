import React from "react";
import "../Common.css";

const Service = () => {
  return (
    <section class="section__container_1 service__container_1" id="service">
      <p class="section__subheader_1">Our Services</p>
      <h2 class="section__header_1">What We Do?</h2>
      <p class="section__description_1">
        We specialize in a wide range of web development services designed to
        transform your vision into reality.
      </p>
      <div class="service__grid_1">
        <div class="service__card_1">
          <span>
            <i class="ri-window-fill"></i>
          </span>
          <h4>Website Design</h4>
          <p>
            We craft user-friendly interfaces that engage visitors and help you
            achieve your online goals with minimum efforts.
          </p>
        </div>
        <div class="service__card_1">
          <span>
            <i class="ri-store-line"></i>
          </span>
          <h4>E-commerce Solutions</h4>
          <p>
            We build secure, scalable, and user-centric online stores that
            enhance the shopping experience and drive conversions.
          </p>
        </div>
        <div class="service__card_1">
          <span>
            <i class="ri-smartphone-line"></i>
          </span>
          <h4>Mobile Development</h4>
          <p>
            From iOS to Android, we create apps that deliver seamless
            performance and keep users coming back for more.
          </p>
        </div>
        <div class="service__card_1">
          <span>
            <i class="ri-share-fill"></i>
          </span>
          <h4>Content Marketing</h4>
          <p>
            Our services include creating blog posts, videos, and social media
            content that drives traffic and engagement.
          </p>
        </div>
        <div class="service__card_1">
          <span>
            <i class="ri-seo-line"></i>
          </span>
          <h4>SEO</h4>
          <p>
            Our SEO strategies are tailored to your specific goals, helping you
            reach your target audience and grow your online presence.
          </p>
        </div>
        <div class="service__card_1">
          <span>
            <i class="ri-share-circle-line"></i>
          </span>
          <h4>Digital Marketing</h4>
          <p>
            From pay-per-click (PPC) advertising to social media marketing, we
            ensure your brand stands out in the crowded digital landscape.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Service;
