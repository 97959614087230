import React, { useState } from 'react';
import { Card, Flex } from 'antd';
import ProjectCard from './ProjectCard';
import cardData from './Data';
const tabListNoTitle = [
    {
        key: '',
        label: <span style={{ fontSize: "20px " }}>All</span>,
    },
    {
        key: 'web',
        label: <span style={{ fontSize: "20px " }}>Web</span>,
    },
    {
        key: 'app',
        label: <span style={{ fontSize: "20px " }}>App</span>,
    },
    {
        key: 'ui',
        label: <span style={{ fontSize: "20px " }}>Ui/Ux</span>,
    },

];



const FilterProjectPage = () => {
    const [activeTabKey2, setActiveTabKey2] = useState('');

    const onTab2Change = (key) => {
        setActiveTabKey2(key);
    };

    return (
        <>

            <Card
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "50px",
                    width: '100%',
                    background: "transparent",


                }}
                tabList={tabListNoTitle}
                activeTabKey={activeTabKey2}

                onTabChange={onTab2Change}
                tabProps={{
                    size: 'middle',
                }}
            >
                <Flex style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "50px",
                }}>
                    {activeTabKey2 ?
                        cardData.filter((res) => res.type === activeTabKey2).map((res) => (<ProjectCard {...res} />))
                        :
                        cardData.map((res) => (<ProjectCard {...res} />))
                    }
                </Flex>

            </Card>
        </>
    );
};

export default FilterProjectPage;
