import React from "react";
import "./About.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
const AboutPage = () => {
  return (
    <>
      <div className="about">
        <div className="about__container">
          <Navbar />
          <div className="about__content">
            <h1>
              We are a fully
              <br />
              integrated{" "}
              <span>
                digital
                <br />
                agency.
              </span>
            </h1>
            {/* <a href="#" className="start__project">
              Start a project
            </a> */}
            <Link to="/project" className="start__project">
              Start a project
            </Link>
          </div>
          <div className="about__cards">
            <div className="about__card">
              <h4>01</h4>
              <h5>User Research</h5>
              <p>
                The process of gathering feedback from users to inform the
                design and development of products.
              </p>
            </div>
            <div className="about__card">
              <h4>02</h4>
              <h5>User Experience</h5>
              <p>
                It refers to the overall experience and satisfaction that a
                person has while interacting with a product.
              </p>
            </div>
            <div className="about__card">
              <h4>03</h4>
              <h5>User Interface</h5>
              <p>
                It refers to the design of software products to enable users to
                interact with them in an intuitive manner.
              </p>
            </div>
            <div className="about__card">
              <h4>04</h4>
              <h5>Usability Testing</h5>
              <p>
                A process of evaluating a product or service by testing it with
                representative users.
              </p>
            </div>
            <div className="about__card">
              <h4>05</h4>
              <h5>Interaction Design</h5>
              <p>
                The process of creating digital products that are user-friendly,
                intuitive, and enjoyable to use.
              </p>
            </div>
            <div className="about__card">
              <h4>06</h4>
              <h5>Front-end Dev</h5>
              <p>
                Involves designing the visual and interactive elements of a
                website that users interact with.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutPage;
