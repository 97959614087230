import React from "react";
import Navbar from "../Components/Navbar/Navbar";

const Iot = () => {
  return (
    <div>
      <Navbar />
      <div>IOT</div>
    </div>
  );
};

export default Iot;
