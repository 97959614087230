import React from 'react'
import "./Pricing.css"
const Pricing = () => {
    return (
        <div class="section__price__container price__container" id="price">
            <p class="section___subheader">BEST PACKAGES</p>
            <h2 class="section__header">Our Pricing Plans</h2>
            <p class="work__subheader">
                We offer a range of affordable and flexible pricing options.
            </p>
            <div class="price__grid">
                <div class="price__card">
                    <h4>SILVER PACKAGE</h4>
                    <h3><sup>$</sup>35</h3>
                    <p>Routine Maintenance</p>
                    <p>Diagnostic Services</p>
                    <p>Wheel Alignment</p>
                    <p>Brake and Suspension</p>
                    <p>Air Conditioning</p>
                    <p>Scheduled Maintenance</p>
                    <button class="price__btn">Go Basic</button>
                </div>
                <div class="price__card">
                    <div class="price__card__ribbon">BESTSELLER</div>
                    <h4>PLATINUM PACKAGE</h4>
                    <h3><sup>$</sup>69</h3>
                    <p>Routine Maintenance</p>
                    <p>Diagnostic Services</p>
                    <p>Engine Tune-Ups</p>
                    <p>Tire Sales and Services</p>
                    <p>Exhaust System Repairs</p>
                    <p>Scheduled Maintenance</p>
                    <button class="price__btn">Go Premium</button>
                </div>
                <div class="price__card">
                    <h4>GOLD PACKAGE</h4>
                    <h3><sup>$</sup>39</h3>
                    <p>Routine Maintenance</p>
                    <p>Diagnostic Services</p>
                    <p>Brake and Suspension</p>
                    <p>Scheduled Maintenance</p>
                    <p>Wheel Alignment</p>
                    <p>Air Conditioning</p>
                    <button class="price__btn">Go Standard</button>
                </div>
            </div>
        </div>
    )
}

export default Pricing
