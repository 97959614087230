import React from "react";

const Sorry = () => {
  return (
    <div>
    
      <div>Sorry Page</div>
    </div>
  );
};

export default Sorry;
