import React from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import Seeklogo from "../Assets/favicon.png";
import DrawerBtn from "../DrawerBtn";
import {
  DownOutlined,
  FundProjectionScreenOutlined,
  AndroidOutlined,
  ChromeOutlined,
  AuditOutlined,
  BranchesOutlined,
} from "@ant-design/icons";
import { Button, Drawer, Dropdown, Flex, message, Space } from "antd";
const boxStyle = {
  width: '100%',
  height: 120,
  marginTop: "20px"

};
const items = [
  {
    label: <Link to="/nft"> NFT</Link>,
    key: "1",
    icon: <FundProjectionScreenOutlined />,
  },
  {
    label: <Link to="/webDevelopment"> Web Development</Link>,
    key: "2",
    icon: <ChromeOutlined />,
  },
  {
    label: <Link to="/androidDevelopment"> Android Development</Link>,
    key: "3",
    icon: <AndroidOutlined />,
  },
  {
    label: <Link to="/uiUxDesign"> UI/UX Design</Link>,
    key: "4",
    icon: <AuditOutlined />,
  },
  {
    label: <Link to="/iotDevelopment"> IoT Development</Link>,
    key: "5",
    icon: <BranchesOutlined />,
  },
];

const menuProps = {
  items,
};

const Navbar = () => {
  return (
    <Flex gap="middle" align="flex-end" vertical>
      <Flex style={{ justifyContent: "space-around", alignItems: "flex-start", ...boxStyle }}>

        <div className="brand_logo">
          <Link to="/">
            <img src={Seeklogo} alt="logo" />
          </Link>
        </div>


        {/* <div className="nav__menu__btn" id="menu-btn">
          <DrawerBtn />
        </div> */}


        <div>
          <ul className="nav__links" id="nav-links">
            <li>
              <Link to="/home">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>

            <li>
              <Link>
                <Dropdown menu={menuProps}>
                  <Button
                    type="text"
                    style={{
                      padding: 0,
                      fontSize: 15,
                    }}
                  >
                    <Space>
                      Service
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Link>
            </li>
            <li>
              <Link to="/project">Projects</Link>
            </li>
            <li>
              <Link to="/team">Team</Link>
            </li>
            <li>
              <Link to="/contact">Contacts</Link>
            </li>
            <li>
              <Link to="/feedback">Feedback</Link>
            </li>
          </ul>
        </div>

        <div>
          <Link to="/login">
            <button className="nav-btn nav__btn">Login Now</button>
          </Link>
        </div>

      </Flex>
    </Flex>
  );
};

export default Navbar;

