import React, { Fragment } from 'react'
import { Avatar, Card } from 'antd';
const { Meta } = Card;




const ProjectCard = ({ cover, alt, title, avatar, desc }) => {
    return (
        <Fragment>
            <Card
                style={{
                    width: 300,
                }}
                cover={
                    <img
                        alt={alt}
                        src={cover}
                    />
                }

            >
                <Meta
                    avatar={<Avatar src={avatar} />}
                    title={title}
                    description={desc}
                />
            </Card>
        </Fragment>
    )
}

export default ProjectCard
