const CodingData = [
    {
        javascript: "js",
        python: "py",
        java: "java",
        csharp: "csharp",
        cpp: "cpp",
        ruby: "ruby",
        swift: "swift",
        go: "go",
        typescript: "ts",
        php: "php",
        rust: "rust",
        kotlin: "kotlin",
        scala: "scala",
        html: "html",
        css: "css",
        sql: "sql",
        shell: "shell",
        perl: "perl",
        bash: "bash",
        objectivec: "objc",
        r: "r",
        groovy: "groovy",
        matlab: "matlab",
        lua: "lua",
        dart: "dart",

        react: "react",
        angular: "angular",
        vue: "vue.js",
        django: "django",
        flask: "flask",
        spring: "spring",
        express: "express.js",
        rubyonrails: "rubyonrails",
        laravel: "laravel",
        nodejs: "node.js",
        dotnet: "dotnet",
        rails: "rubyonrails",
        flask: "flask",
        express: "express.js",
        flask: "flask",
        laravel: "laravel",
        symfony: "symfony",
        play: "playframework",
        mysql: "MySQL",
        postgresql: "PostgreSQL",
        mongodb: "MongoDB",
        sqlite: "SQLite",
        oracle: "Oracle",
        sqlserver: "SQL Server",
        redis: "Redis",
        cassandra: "Cassandra",
        couchdb: "CouchDB",
        firebase: "Firebase",

        sequelize: "Sequelize (for MySQL, PostgreSQL, SQLite)",
        mongoose: "Mongoose (for MongoDB)",
        typeorm: "TypeORM (for multiple databases)",
        entityframework: "Entity Framework (for .NET and SQL Server)",
        knex: "Knex.js (SQL query builder)",
        springdata: "Spring Data (for Java and multiple databases)",
        djangoorm: "Django ORM (for Python and multiple databases)",
        activerecord: "Active Record (for Ruby on Rails)",
        doctrine: "Doctrine (for PHP and multiple databases)",
        fluentnh: "Fluent NHibernate (for .NET and multiple databases)",
    },
]

export default CodingData


