import React from 'react'
import "./BarLine.css"
import { Button, Flex } from 'antd'
import { Link } from 'react-router-dom'
const barline__bg = {
    backgroundColor: "#1E2234",
    padding: "80px",
    fontSize: "24px"
}
const baseline__btn = {
    backgroundColor: "white",
    color: "black",
    border: "none"
}

const BarLine = () => {
    return (
        <div style={barline__bg} xs={24} xm={24}>
            <Flex justify='space-around' align='center' xs={24} xm={24}>
                <div className='barline__data' xs={24} xm={24}>
                    <h2>We're Ready For You. Learn With Us Now!</h2>
                </div>
                <div className='barline__btn' xs={24} xm={24}>
                    <Link to="/contact">
                        <Button style={baseline__btn}>Contact us</Button>
                    </Link>
                </div>
            </Flex>
        </div>
    )
}

export default BarLine
