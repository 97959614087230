import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Feedback from "./Pages/Contact";
import Project from "./Pages/Project";
import Service from "./Pages/Service";
import Team from "./Pages/Team";
import Sorry from "./Pages/Sorry";
import LoginPage from "./Pages/LoginPage";
import { ConfigProvider, theme } from "antd";
import Toogle from "./Components/Toogle";
import Nft from "./Pages/Nft";
import Web from "./Pages/Web";
import Android from "./Pages/Android";
import Iot from "./Pages/Iot";
import UiUx from "./Pages/UiUx";
// import Navbar from "./Components/Navbar/Navbar";

function App() {
  const { defaultAlgorithm, darkAlgorithm } = theme;
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            primaryColor: "red",
          },
        },
        algorithm: darkAlgorithm,
      }}
    >
      <div className="App">
        {/* <Navbar /> */}
        <Toogle />
        <BrowserRouter>
          <Routes>
            <Route path="" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/project" element={<Project />} />
            <Route path="/service" element={<Service />} />
            <Route path="/team" element={<Team />} />
            <Route path="/nft" element={<Nft />} />
            <Route path="/webDevelopment" element={<Web />} />
            <Route path="/androidDevelopment" element={<Android />} />
            <Route path="/iotDevelopment" element={<Iot />} />
            <Route path="/uiUxDesign" element={<UiUx />} />
            <Route path="*" element={<Sorry />} />

            {/* Login page   */}
            <Route path="/login" element={<LoginPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    </ConfigProvider>
  );
}

export default App;
