import React from "react";
import "../Common.css";
import banner from "../Assets/ssllplogo.svg";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <div>
      <div class="header_1">
        <div class="header__socials_1">
          <Link to="mailto:admin.dev@seeksolution.in?Subject=To know more..&body=Hello Sir/Maam,%0AI wanted to know more about your company...%0A">
            <i class="ri-mail-fill"></i>
          </Link>
          <Link to="#">
            <i class="ri-github-fill"></i>
          </Link>
          <Link
            to="https://www.linkedin.com/company/seeksolutionllp/"
            target="_blank"
          >
            <i class="ri-linkedin-fill"></i>
          </Link>
        </div>
      </div>
      <section class="section__container_1 about__container_1">
        <div class="about__image_1">
          <img src={banner} alt="about" />
        </div>
        <div class="about__content_1">
          <h2 class="section__header_1">Hi There I'm a Seek Solution</h2>
          <p>
            At SeekSolution, we are a cutting-edge software company dedicated to
            delivering innovative solutions that transform businesses. With a
            passion for technology, our team of experts crafts custom software
            applications, harnessing the power of the latest technologies to
            drive efficiency, enhance user experiences.
          </p>
          <h4>
            propel our clients toward success. From robust software development
            to strategic consulting, we empower businesses to thrive in the
            digital era. Discover the difference of partnering with [Company
            Name] for your software needs.
          </h4>
          <div class="about__btns_1">
            <Link to="tel:+917266800839" download class="download__btn_1">
              Call us now
            </Link>
            <Link to="mailto:admin.dev@seeksolution.in?Subject=To know more..&body=Hello Sir/Maam,%0AI wanted to know more about your company...%0A">
              <i class="ri-mail-fill"></i>
            </Link>
            <Link to="/" target="_blank">
              <i class="ri-github-fill"></i>
            </Link>
            <Link
              to="https://www.linkedin.com/company/seeksolutionllp/"
              target="_blank"
            >
              <i class="ri-linkedin-fill"></i>
            </Link>
          </div>
        </div>
      </section>

      <section class="section__container_1 banner__container_1">
        <div class="banner__card_1">
          <span>
            <i class="ri-pen-nib-line"></i>
          </span>
          <div>
            <h4>Design</h4>
            <p>
              Our designers and content creators are committed to delivering
              engaging digital content.
            </p>
          </div>
        </div>
        <div class="banner__card_1">
          <span>
            <i class="ri-layout-masonry-line"></i>
          </span>
          <div>
            <h4>Development</h4>
            <p>
              We stay at forefront of technological advancements, ensuring your
              digital presence performant.
            </p>
          </div>
        </div>
        <div class="banner__card_1">
          <span>
            <i class="ri-checkbox-line"></i>
          </span>
          <div>
            <h4>Testing & QA</h4>
            <p>
              We understand that your digital presence should not just look
              good, it should deliver traffic & engagement.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Banner;
