import React from "react";
import {
  CommentOutlined,
  CustomerServiceOutlined,
  HeartOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { FloatButton } from "antd";

const Toogle = () => (
  <>
    <FloatButton.Group
      trigger="click"
      type="primary"
      style={{
        right: 24,
      }}
      icon={<CustomerServiceOutlined />}
    >
      <FloatButton />
      <FloatButton icon={<CommentOutlined />} />
      <FloatButton icon={<HeartOutlined />} />
      <FloatButton icon={<StarOutlined />} />
    </FloatButton.Group>
  </>
);

export default Toogle;
